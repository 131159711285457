<template>
  <div>
    <div class="custom-search">
      <!-- advance search input -->
      <b-form-group :label="$t('global.search')" label-for="search">
        <b-form-input
          name="search"
          :placeholder="$t('global.search-term')"
          type="text"
          class="d-inline-block"
          style="max-width: 300px"
          @input="advanceSearch"
        />
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :isLoading="isTableBusy"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
      @on-row-click="onRowClick"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'fullName'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span>{{ props.row.fullName }}</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  props: {
    dataVendors: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      selectedVendors: {
        vendors: [],
        id: null,
      },
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    ...mapState("categoryManagers", ["isTableBusy"]),
    columns() {
      return [
        {
          label: this.$t("users.id"),
          field: "vendor.id",
        },
        {
          label: this.$t("application.brand-name-ge"),
          field: "vendor.brandNameGeo",
        },
        {
          label: this.$t("application.brand-name-eng"),
          field: "vendor.brandNameEng",
        },
        {
          label: this.$t("application.email"),
          field: "vendor.email",
        },
        {
          label: this.$t("application.mobile"),
          field: "vendor.mobile",
        },
      ];
    },
  },
  created() {
    this.rows = this.dataVendors.data.vendors;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < this.rows.length; i++) {
      this.$set(this.rows[i], "vgtSelected", this.rows[i].isAssigned);
    }
  },
  methods: {
    ...mapActions("categoryManagers", ["setCategoryRoles"]),
    advanceSearch(val) {
      this.searchTerm = val;
    },
    onRowClick(params) {
      this.selectedVendors.vendors.push(params.row.vendor.id);
      this.selectedVendors.id = params.row.categotyManagerId;
      this.$emit("vendorData", this.selectedVendors);
    },
  },
};
</script>
