<template>
  <b-modal
    id="category-manager-modal"
    size="xl"
    centered
    :ok-title="$t('global.update')"
    :cancel-title="$t('global.cancel')"
    @ok="addVendor"
  >
    <h1 class="d-flex justify-content-center mb-2">
      {{ `${user.firstName} ${user.lastName}  ${$t("navigation.vendors")}` }}
    </h1>
    <VendorsTable
      :data-vendors="categoryManagersWithVendors"
      @vendorData="getVendors"
    />
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import VendorsTable from "./VendorsTable.vue";

export default {
  components: {
    VendorsTable,
  },

  props: {
    categoryManagersWithVendors: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
    user: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedVendors: [],
      vendorId: null,
    };
  },
  methods: {
    ...mapActions("categoryManagers", [
      "setCategoryRoles",
      "getCategoryManagers",
    ]),
    getVendors(value) {
      this.selectedVendors = value.vendors;
      this.vendorId = value.id;
    },
    async addVendor() {
      if (this.selectedVendors.length) {
        await this.setCategoryRoles({
          vendors: this.selectedVendors,
          id: this.vendorId,
        });
        this.getCategoryManagers();
      }
    },
  },
};
</script>
