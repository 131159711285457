<template>
  <b-table
    v-if="categoryManagers"
    :busy="isTableBusy"
    sticky-header
    responsive
    :fields="fields"
    :items="categoryManagers.data.categoryManagers"
    hover
    class="shadow-sm rounded products-table"
    thead-tr-class="order-main-thead"
    primary-key="id"
    show-empty
    empty-text="No matching records found"
    small
    @row-clicked="(e) => onRowClick(e)"
  />
</template>

<script>
import { BTable } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BTable,
  },
  props: {
    categoryManagers: {
      type: [Array, Object],
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState("categoryManagers", ["isTableBusy"]),
    fields() {
      return [
        { key: "displayId", label: this.$t("users.id") },
        { key: "firstName", label: this.$t("users.firstName") },
        { key: "lastName", label: this.$t("users.lastName") },
        { key: "email", label: this.$t("users.email") },
        { key: "mobile", label: this.$t("users.mobile") },
      ];
    },
  },
  methods: {
    onRowClick(e) {
      // this.copy(e.email);
      this.$emit("onCopyUser", e);
    },
    async copy(s) {
      await navigator.clipboard.writeText(s);
    },
  },
};
</script>
