<template>
  <b-card
    :header="$t('navigation.category-management')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <!-- basic -->
    <CategoryManagerPopUp
      v-if="categoryManagersWithVendors"
      :show-vendors-popup="showVendorsPopup"
      :category-managers-with-vendors="categoryManagersWithVendors"
      :user="user"
    />
    <CategoryManagersTable
      :category-managers="categoryManagers"
      @onCopyUser="setUser"
    />
  </b-card>
</template>

<script>
import { required, email } from "@validations";
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import CategoryManagersTable from "./CategoryManagersTable.vue";
import CategoryManagerPopUp from "./CategoryManagerPopUp.vue";

export default {
  components: {
    BCard,
    CategoryManagersTable,
    CategoryManagerPopUp,
  },
  data() {
    return {
      required,
      email,
      userEmail: "",
      user: null,
      showVendorsPopup: false,
    };
  },
  computed: {
    ...mapState("categoryManagers", [
      "categoryManagers",
      "categoryManagersWithVendors",
    ]),
  },
  created() {
    this.getCategoryManagers();
  },
  methods: {
    ...mapActions("categoryManagers", [
      "getCategoryManagers",
      "getCategoryManagerWithVendors",
    ]),
    setUser(user) {
      this.userEmail = user.email;
      this.user = user;
      this.getUserRoles();
    },
    async getUserRoles() {
      await this.getCategoryManagerWithVendors({ id: this.user.id });
      this.$bvModal.show("category-manager-modal");
    },
  },
};
</script>

<style lang="scss">
.checkboxWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
